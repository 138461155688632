.login-wrapper {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.login-item {
    display: grid;
    align-self: center;
    width: 300px;
    max-width: 100%;
    margin: 10px 0 10px 0;
}

.login-alert {
    display: grid;
    align-self: center;
    width: 300px;
    max-width: 100%;
    margin: 20px 0 0 0;
    color: #8d8d8d;
}

.login-alert-text {
    font-size: 10px;
}

.login-error {
    color: red;
}

h1,h2,h3,h4,h5,h6,label {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
}