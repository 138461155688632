.outerDiv {

}

body {
    background: #1C8EF9 !important;
    font-weight: 400;
    font-family: 'Fira Sans', sans-serif;
}

body, html, .outerDiv, #root {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.auth-wrapper {
    text-align: center;
}

.auth-inner {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 249px;
    width: 14%;
    height: 300px;
    min-height: 26%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
}
